import { Container, Banner, BannerColorTheme } from 'ui/components';
import { spacingMapper } from 'helpers/mappers/spacingMapper';

export const BannerFeature: React.FC<Content.Banner> = ({ content }) => {
	const { colorTheme, image, spacing } = content?.properties ?? {};
	const colorThemeLowerCase = colorTheme?.toLowerCase() ?? 'light';

	return (
		<Container width="std" section spacingBottom={spacingMapper(spacing)}>
			<Banner
				{...content?.properties}
				image={{
					url: image?.url,
					altText: image?.alt,
				}}
				colorTheme={colorThemeLowerCase as BannerColorTheme}
			/>
		</Container>
	);
};
