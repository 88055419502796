import { segment, setSegment } from 'application/adapters/store/slices/segmentSlice';
import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { useNavigationContext } from 'application/contexts/NavigationContext';
import { useSiteContext } from 'application/contexts/SiteContext';
import cx from 'classnames';
import { NewsSubscriptionFormFeature } from 'features/Modules';
import { getUrlPathname } from 'helpers/getUrlPathname/getUrlPathname';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Layout } from 'ui/components';
import { FooterFeature } from '../Footer';
import { HeaderFeature } from '../Header';
import { Meta } from './Meta';
import { CookieConsent } from './Meta/CookieConsent';
import { HtmlBlock } from './Meta/HtmlBlock';
import { Siteimprove } from './Meta/Siteimprove';
import { SearchMetadata } from './Meta/SearchMetadata';

export const LayoutFeature: React.FC<Content.PageContent> = ({
	children,
	themeSettings,
	page,
	footer,
	serviceNavigation,
	searchPageUrl,
	siteForms,
	searchMetadata,
}) => {
	const siteContext = useSiteContext();
	const router = useRouter();
	const dispatch = useDispatch();
	const dictionary = useDictionaryContext();
	const { headHtml, bodyHtml, siteimproveId } = siteContext ?? {};
	const navContext = useNavigationContext();
	const formDictionary: UI.FormDictionary = {
		emailErrorMessage: dictionary.getValue('Form.EmailErrorMessage', null, 'Incorrect email format'),
		requiredErrorMessage: dictionary.getValue('Form.RequiredErrorMessage', null, 'This field is required'),
		selectErrorMessage: dictionary.getValue('Form.SelectErrorMessage', null, 'Please select an option'),
		multiSelectErrorMessage: dictionary.getValue(
			'Form.MultiSelectErrorMessage',
			null,
			'Please select at least one option',
		),
		loadingOptionsMessage: dictionary.getValue('Form.LoadingOptionsMessage', null, 'Loading options...'),
	};
	const currentSegment = useSelector(segment);
	const segmentPages =
		navContext?.getSegmentPages({
			host: siteContext?.host,
			currentSegmentPath: currentSegment,
		}) ?? [];
	const mappedServiceNavigation = [...segmentPages, ...(serviceNavigation ?? [])];

	// Update global segment state if current page is part of a segment
	useEffect(() => {
		if (!segmentPages?.length) return;

		const currentSegmentPage = segmentPages?.find((item) => {
			const itemPathname = getUrlPathname(item.url, siteContext?.host);

			return router.asPath.startsWith(itemPathname);
		});

		if (currentSegmentPage) {
			// If current page is part of a segment, update global segment state to that segment
			dispatch(setSegment(getUrlPathname(currentSegmentPage.url, siteContext?.host)));
		} else if (!currentSegment) {
			// If current page is not part of a segment and global segment state is not set, set it to the first segment
			dispatch(setSegment(getUrlPathname(segmentPages[0].url, siteContext?.host)));
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [segmentPages, router.asPath]);

	return (
		<div className={cx('page-wrapper', { [`theme__${themeSettings?.color}`]: themeSettings?.color })}>
			<Layout router={router} formDictionary={formDictionary}>
				<CookieConsent />
				<Meta {...page?.properties} favicon={themeSettings?.favicon} />
				<SearchMetadata metadata={searchMetadata} />
				{headHtml && <HtmlBlock headHtml={headHtml} bodyHtml={bodyHtml} />}
				<HeaderFeature
					themeSettings={themeSettings}
					serviceNavigation={mappedServiceNavigation}
					searchPageUrl={searchPageUrl}
				/>
				<noscript>
					<p>
						<em>
							{dictionary.getValue(
								'Accessibility.NoJavaScript.General',
								null,
								'Din browser understøtter ikke JavaScript hvilket kræves for, at alle denne hjemmesides komponenter fungerer optimalt.',
							)}
						</em>
					</p>
				</noscript>
				{children}
				{footer ? <FooterFeature footer={footer} themeSettings={themeSettings} /> : null}
				{siteForms?.map((form, index) => {
					// At the time of this writing we only have one type of form, but we use a switch statement
					// here to make it easy to implement new form types
					switch (form?.documentType) {
						case 'newsSubscriptionForm': {
							return <NewsSubscriptionFormFeature {...form} key={index} />;
						}
						default:
							return <NewsSubscriptionFormFeature {...form} key={index} />;
					}
				})}
				<Siteimprove siteimproveId={siteimproveId} />
			</Layout>
		</div>
	);
};
