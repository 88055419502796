import { Container, LinkBars } from 'ui/components';
import { spacingMapper } from 'helpers/mappers/spacingMapper';

export const LinkBarsFeature: React.FC<Content.LinkBars> = ({ content }) => {
	const { spacing } = content?.properties ?? {};

	return (
		<Container section width="std" spacingBottom={spacingMapper(spacing)}>
			<LinkBars {...content?.properties} />
		</Container>
	);
};
