
export const convertArrayToObject = <Ttype>(array: Ttype[], key: string): { [key: string]: Ttype } => {
	return array.reduce((obj, item) => {
		return { ...obj, [item[key]]: item };
	}, {});
};

/**
 *
 * @param array Array to be sorted
 * @param keyToSortBy Specific key to sort by. The value be a string.
 * @param sortOrder Ascending or descending. Ascending by default
 * @returns Array
 */
export const alphabeticallySortArray = <Ttype, Key extends keyof Ttype>(
	array: Ttype[],
	keyToSortBy: Key,
	sortOrder: 'asc' | 'desc' = 'asc',
): Ttype[] => {
	return array.sort((a, b) => {
		if (sortOrder === 'asc') {
			return (a[keyToSortBy] as string).localeCompare(b[keyToSortBy] as string);
		} else {
			return (b[keyToSortBy] as string).localeCompare(a[keyToSortBy] as string);
		}
	});
};
