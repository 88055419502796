import { usePageContext } from 'application/contexts/PageContext';
import { handleShyHeading } from 'helpers/string/string';
import { HeroCanvasPage } from 'ui/components';

export const HeroCanvasFeature: React.FC<Content.HeroCanvas> = ({ content }) => {
	const PageContext = usePageContext();
	const { themeSettings } = PageContext;

	const { image, lead, imageDisableBottomSpacing } = content?.properties ?? {};
	const img: UI.Image = image
		? {
				url: image.url,
				altText: image.alt ?? '',
		  }
		: null;

	return (
		<HeroCanvasPage
			image={img}
			theme={themeSettings?.heroAndBreadcrumbColorTheme}
			heading={handleShyHeading(content?.properties)}
			lead={lead}
			imageDisableSpacingBottom={imageDisableBottomSpacing}
		/>
	);
};
