import { CONTENT_API_URL } from 'application/adapters/cms/_constants';
import { axiosInstance as axios } from 'helpers/axios/axiosInstance';
import logger from 'helpers/logger';
import { minimalError } from 'helpers/error/minimalError';

export const subscribeToNewsletterListsApi = (
	data: Models.SubscribeToNewsletterLists,
): Promise<Models.SubscribeToNewsletterListsResponse> => {
	const contentApiUrl = CONTENT_API_URL ?? process.env.NEXT_PUBLIC_CONTENT_API_URL;
	const url = `${contentApiUrl}api/UbivoxSubscription/Subscribe`;
	return axios({
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		url,
		data,
	})
		.then((res) => res.data)
		.catch((error) => {
			logger.error(
				`[${error?.response?.status}] NEWSLETTER_SUBSCRIPTION_API_ERROR \nFailed newsletter subscription request`,
				minimalError(error),
			);
			throw error;
		});
};
