import { spacingMapper } from 'helpers/mappers/spacingMapper';
import { Container, RichText } from 'ui/components';

export const RichTextEditorFeature: React.FC<Content.RichTextEditor> = ({ content }) => {
	const { spacing } = content?.properties ?? {};

	return (
		<Container section width="sm" spacingBottom={spacing ? spacingMapper(spacing) : 'sm'}>
			<RichText {...content?.properties} />
		</Container>
	);
};
