import cx from 'classnames';
import styles from './HeroShortcuts.module.scss';
import { Container, Heading, LinkButton } from 'ui/components';

export interface HeroShortcutsProps {
	className?: string;
	heading: string;
	links: UI.LinkSimple[];
	colorTheme: UI.GeneralColorTheme;
}

export const HeroShortcuts: React.FC<HeroShortcutsProps> = ({ className, heading, links, colorTheme = 'light' }) => (
	<Container
		section
		width={'fluid'}
		className={cx(styles.HeroShortcuts, styles[`HeroShortcuts___${colorTheme}`], className)}
	>
		{heading && (
			<Heading headingLevel={'h2'} style={'md'}>
				{heading}
			</Heading>
		)}
		<ul className={styles.HeroShortcuts_list}>
			{links.map((link, i) => (
				<li key={i}>
					<LinkButton className={styles.HeroShortcuts_link} style={'primary-negative'} size={'sm'} {...link}>
						{link.name}
					</LinkButton>
				</li>
			))}
		</ul>
	</Container>
);
