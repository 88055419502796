import { capitalizeFirstLetter } from 'helpers/string/string';
import { FormfieldCheckboxProps } from 'ui/components';

export const generateYearSelectboxes = (
	startYear: number,
	endYear: number,
	baseId: string,
	defaultValue?: number,
): FormfieldCheckboxProps[] => {
	const toReturn: FormfieldCheckboxProps[] = [];
	let start = startYear;
	const end = endYear;
	while (start <= end) {
		toReturn.push({
			id: `${baseId}-${start.toString()}`,
			labelText: start.toString(),
			name: `checkbox-year-${start}`,
			value: start.toString(),
			checked: defaultValue === start,
		} as FormfieldCheckboxProps);

		start++;
	}

	return toReturn.reverse();
};

export const generateMonthSelectboxes = (
	baseId: string,
	culture: Content.Culture,
	defaultValue?: number,
): FormfieldCheckboxProps[] => {
	const year = new Date().getFullYear();
	const monthList = [...Array(12).keys()];
	const formatter = new Intl.DateTimeFormat(culture, {
		month: 'long',
	});

	const getMonthName = (monthIndex: number): FormfieldCheckboxProps => {
		const value = capitalizeFirstLetter(formatter.format(new Date(year, monthIndex)));

		const toReturn = {
			id: `${baseId}-${value}`,
			labelText: value,
			name: `checkbox-month-${value}`,
			value: (monthIndex + 1).toLocaleString(culture, {
				minimumIntegerDigits: 2,
			}),
			checked: defaultValue === monthIndex + 1,
		} as FormfieldCheckboxProps;
		return toReturn;
	};
	return monthList.map((value) => getMonthName(value));
};
