import { axiosInstance as axios } from 'helpers/axios/axiosInstance';
import logger from 'helpers/logger';

export const getJobListFromRepository = (params: Models.JobList): Promise<Models.Job> => {
	const { departmentId } = params;

	const url = 'https://api.hr-manager.net/jobportal.svc/statens_erekruttering/positionlist/json/';
	const searchParams = new URLSearchParams();
	searchParams.append('depid', departmentId.toString());
	const fullUrl = `${url}?${searchParams.toString()}`;

	return axios(fullUrl)
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			logger.error(`[${error?.response?.status}] JOBLIST_API_ERROR \nFailed retrieving joblist from API`, {
				error,
			});
			throw error;
		});
};
