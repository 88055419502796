import { usePageContext } from 'application/contexts/PageContext';
import { DynamicModuleFeature } from 'features/Modules';
import { Main } from 'ui/components';

export const HomePageFeature: React.FC<Content.HomePage> = ({ properties }) => {
	const { pageHeader, pageSections } = properties ?? {};
	const pageSectionsTopSpacing = usePageContext().getSettings('pageSectionsTopSpacing');

	return (
		<Main
			header={pageHeader.map((headerModule, index) => (
				<DynamicModuleFeature key={index} contentModule={headerModule} />
			))}
			renderAsArticle={false}
			contentTopSpacing={pageSectionsTopSpacing?.disableTopSpacing ? false : true}
		>
			{pageSections.map((sectionModule, index) => (
				<DynamicModuleFeature key={index} contentModule={sectionModule} />
			))}
		</Main>
	);
};
