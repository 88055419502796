import { Footer } from 'ui/components';
import { usePageContext } from 'application/contexts/PageContext';
import { useDictionaryContext } from 'application/contexts/DictionaryContext';

interface FooterFeatureProps {
	footer: Content.PageContent['footer'];
	themeSettings: Models.Settings.ThemeSettings;
}

export const FooterFeature: React.FC<FooterFeatureProps> = ({ footer, themeSettings }) => {
	const pageContext = usePageContext();
	const dictionary = useDictionaryContext();

	if (!footer) return null;

	const languageSelectorLinks = footer?.languageSelector
		?.map((lang) => {
			return {
				...lang,
				isActive: pageContext.url?.startsWith(lang?.url),
			};
		})
		?.sort((a) => (a.isActive ? -1 : 1));

	return (
		<Footer
			logo={{
				heading: themeSettings?.name,
				logoUrl: themeSettings?.logoUrl,
				linkUrl: '/',
				headerColorTheme: 'dark',
			}}
			cards={footer.cards}
			social={{
				heading: footer.socialHeading,
				links: footer.socialLinks,
			}}
			partnerSites={footer.partnerSites}
			languageSelector={{
				languageSelector: '',
				heading: dictionary.getValue('Language', null, 'Language'),
				links: languageSelectorLinks,
			}}
			links={footer.policyLinks}
			theme={themeSettings?.footerColorTheme}
		/>
	);
};
