import { TeaserBaseParams } from 'application/repositories/teaserRepository';
import { axiosInstance as axios } from 'helpers/axios/axiosInstance';
import logger from 'helpers/logger';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

const limitPerPage = 10;
export interface ContentPageOverviewParams extends TeaserBaseParams {
	to?: string;
	from?: string;
	direction?: 'ascending' | 'descending'; // API default is 'descending'
	subjects: Content.BaseSubject[];
	searchTerm: string;
	/** This to make sure that the searchParams will change and SRW has something to act upon */
	incrementer?: number;
	/** Index of current page that is to be fetched */
	nextPage: number;
}

export const getContentPagesFromApi = async (
	params: ContentPageOverviewParams,
): Promise<Content.ContentPageOverviewResponse> => {
	const { searchTerm, rootKey, categories, subjects, nextPage, to, from, direction, host } = params;
	const bodyContent = JSON.stringify({
		key: rootKey,
		documentTypes: ['articlePage'],
		subjects,
		categories,
		takeAmount: limitPerPage,
		skipAmount: nextPage * limitPerPage,
		to,
		from,
		direction,
		UserTextInputField: searchTerm,
	});
	const headersList = {
		'Content-Type': 'application/json',
		Accept: 'application/json',
		Hostname: host,
	};
	const reqOptions = {
		url: `${publicRuntimeConfig.NEXT_PUBLIC_SEARCH_API_URL}api/News/Search`,
		method: 'POST',
		headers: headersList,
		data: bodyContent,
	};

	const profiler = logger.startTimer();
	return axios
		.post<Content.ContentPageOverviewResponse>(reqOptions.url, reqOptions.data, { headers: reqOptions.headers })
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			const message = error?.response?.data?.message ?? error;
			logger.error(`News retrieval failed. Returning empty response: ${message}`);
			return {
				pagination: null,
				searchResults: [],
			};
		})
		.finally(() => {
			profiler.done({ message: 'WEBSITE: getContentPagesFromApi()', level: 'info' });
		});
};
