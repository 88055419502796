import { Container, QuoteModule, QuoteModuleTheme } from 'ui/components';

export const QuoteModuleFeature: React.FC<Content.QuoteModule> = ({ content }) => {
	const { quoteModuleTheme } = content?.properties ?? {};
	const quoteModuleThemeLowerCase = quoteModuleTheme?.toLowerCase() ?? 'light';

	return (
		<Container width="md" section>
			<QuoteModule
				{...content?.properties}
				
				quoteModuleTheme={quoteModuleThemeLowerCase as QuoteModuleTheme}
			/>
		</Container>
	);
};
