import cx from 'classnames';
import { useRef } from 'react';
import { Container, HeroCanvasPage, SearchResultList, SearchResultListProps } from 'ui/components';
import { SearchFilter, SearchFilterProps } from '../SearchFilter';
import { Pagination, PaginationProps } from 'ui/components/2-molecules/Navigation/Pagination';
import styles from './SearchResultView.module.scss';
import { HeroCanvasPageProps } from 'ui/components';

export interface SearchResultViewProps {
	className?: string;
	hero: HeroCanvasPageProps;
	filterProps: SearchFilterProps;
	resultListProps: SearchResultListProps;
	pagination: PaginationProps;
	fallbackText?: string;
}

export const SearchResultView: React.FC<SearchResultViewProps> = ({
	className,
	hero,
	filterProps,
	resultListProps,
	pagination,
	fallbackText,
}) => {
	const searchResultListRef = useRef<HTMLDivElement>(null);
	const filter = filterProps && (
		<SearchFilter
			{...filterProps}
			onSubmit={() =>
				searchResultListRef?.current && searchResultListRef.current.scrollIntoView({ behavior: 'smooth' })
			}
		/>
	);
	const hasResults = resultListProps?.results?.length > 0;

	return (
		<div className={cx(styles.SearchResultView, className)}>
			<HeroCanvasPage {...hero} searchFilter={filter} />
			<Container>
				<SearchResultList {...resultListProps} resultListRef={searchResultListRef}>
					{!hasResults && fallbackText && <p>{fallbackText}</p>}
				</SearchResultList>
			</Container>
			<Container>
				<Pagination {...pagination} />
			</Container>
		</div>
	);
};
