import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { Container, Slider, SliderNewsItem } from 'ui/components';

export const NewsSliderFeature: React.FC<Content.Slider> = ({ content }) => {
	const dictionary = useDictionaryContext();
	const { slides } = content?.properties ?? {};
	const mappedSlides = slides.map((slide, index) => {
		return (
			<SliderNewsItem dateString={slide?.content?.properties?.date} key={index} {...slide?.content?.properties} />
		);
	});

	const sliderOptions = {
		slideArrowNext: dictionary.getValue('Accessibility.Slider.NextSlide', null, 'Next slide'),
		slideArrowPrevious: dictionary.getValue('Accessibility.Slider.PreviousSlide', null, 'Previous slide'),
		breakpoints: {
			320: {
				slidesPerView: 1,
				spaceBetween: 20,
				pagination: false,
			},
			768: {
				slidesPerView: 2,
				spaceBetween: 40,
			},
			1024: {
				slidesPerView: 3,
				spaceBetween: 40,
			},
			1248: {
				slidesPerView: 4,
				spaceBetween: 60,
			},
		},
	};

	return (
		<Container width="std">
			<Slider {...content?.properties} slides={mappedSlides} sliderOptions={sliderOptions} />
		</Container>
	);
};
