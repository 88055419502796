import { useSiteContext } from 'application/contexts/SiteContext';
import { useEffect, useRef, useState } from 'react';
import { Container, Spinner } from 'ui/components';

export const CookiebotDeclarationFeature: React.FC<Content.EmbedCard> = () => {
	const divRef = useRef<HTMLDivElement>(null);
	const siteContext = useSiteContext();
	const [isLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		if (!siteContext?.cookiebotId) return;

		// Save the current ref for correct unmounting later
		const currentRef = divRef.current;

		// Load the script
		const script = document.createElement('script');
		script.src = `https://consent.cookiebot.com/${siteContext.cookiebotId}/cd.js`;
		script.async = true;
		script.id = 'CookieDeclaration';

		const onScriptLoad = () => setIsLoaded(true);
		script.addEventListener('load', onScriptLoad);

		currentRef?.appendChild(script);

		return () => {
			// Clean up the script and rendered child elements when the component unmounts
			script.removeEventListener('load', onScriptLoad);
			currentRef?.replaceChildren();
		};
	}, [siteContext.cookiebotId]);

	return (
		<Container section width={'md'} spacingBottom={'sm'}>
			{!isLoaded && <Spinner />}
			<div ref={divRef}></div>
		</Container>
	);
};
