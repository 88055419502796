import { ContactPerson, Container } from 'ui/components';
import { useDictionaryContext } from 'application/contexts/DictionaryContext';

export const ContactPersonFeature: React.FC<Content.ContactPerson> = ({ content }) => {
	const { persons } = content?.properties ?? {};

	const dictionary = useDictionaryContext();

	const contactPersonDirtinoary = {
		phoneLabel: dictionary.getValue('ContactPerson.PhoneLabel', null, 'Tlf.'),
		emailLabel: dictionary.getValue('ContactPerson.EmailLabel', null, 'Email'),
		imageAltText: dictionary.getValue('ContactPerson.ImageAltText', null, 'Billede af'),
	};

	let personsTransform = null;
	if (persons) {
		personsTransform = persons.map((person) => {
			// The reason 'Properties' is capitalized is because we didn't want to changed the original data structure and create bugs.
			const { heading, postionAndName, location, phone, email, initials, image } = person?.Properties ?? {};

			return {
				heading,
				postionAndName,
				location,
				phone,
				email,
				initials,
				image: image,
			};
		});
	}

	return (
		<Container width="sm" section>
			<ContactPerson {...content?.properties} dictionary={contactPersonDirtinoary} persons={personsTransform} />
		</Container>
	);
};
