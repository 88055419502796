import { getJobList } from 'application/adapters/jobListAdapter';
import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { listViewPrettyDate } from 'helpers/date/date';
import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { Container, JobList } from 'ui/components';
import { JobCardProps } from 'ui/components/2-molecules/Card/JobCard';
import { useSiteContext } from 'application/contexts/SiteContext';

export const JobListFeature: React.FC<Content.JobList> = ({ content }) => {
	const dictionary = useDictionaryContext();
	const SiteContext = useSiteContext();
	const [jobList, setJobList] = useState<JobCardProps[]>([]);
	const { departmentId, link } = content?.properties;
	const joblistParams: Models.JobList = { departmentId: departmentId };
	const { data, error, isLoading } = useSWR(joblistParams, getJobList, {
		revalidateOnFocus: false,
	});
	const { culture } = SiteContext;

	useEffect(() => {
		if (data) {
			const mappedJobItems: JobCardProps[] = data.Items.map((job) => {
				const localeDate = new Date(parseInt(job.ApplicationDue.replace(/.*\((.*)\).*/, '$1')));
				return {
					job: {
						id: job.Name,
						name: job.Name,
						latestDate: listViewPrettyDate(localeDate, culture),
						location: job.WorkPlace,
						title: job.Name,
						dateTime: localeDate,
					},
					link: {
						url: job.AdvertisementUrlSecure,
						name: dictionary.getValue('JobList.Apply', null, 'Søg stillingen'),
						target: '_blank',
					},
					dictionaryTexts: {
						deadline: dictionary.getValue('JobList.Job.Deadline', null, 'Ansøgningsfrist'),
						location: dictionary.getValue('JobList.Job.Location', null, 'Sted'),
					},
				};
			});
			setJobList(mappedJobItems);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	return (
		<Container spacingBottom={'lg'}>
			{error ? (
				dictionary.getValue('JobList.FailedToLoad', null, 'Indlæsning af joblisten fejlede')
			) : (
				<JobList
					isLoading={isLoading}
					link={link}
					heading={`${dictionary.getValue('JobList.AllVacantJobs', null, 'Alle ledige jobs')} (${
						jobList.length
					})`}
					jobs={jobList}
				/>
			)}
		</Container>
	);
};
